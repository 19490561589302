export function useSustainability() {
  const getSustainabilityLevel = function (categories) {
    if (!categories?.length) {
      return null;
    }

    const sustainabilityInfo = categories[categories.length - 1];
    const sustainabilityIsValid
      = sustainabilityInfo?.category_id && sustainabilityInfo?.category_id !== 'SUSTAINABILITY_TIER_0';
    const sustainabilityTier = sustainabilityInfo?.category_id?.replace('SUSTAINABILITY_TIER_', '');
    return sustainabilityIsValid
      ? {
        tier: sustainabilityTier,
        icon: `sustainability-${sustainabilityTier === '4' ? '3-plus' : sustainabilityTier}`,
        title: sustainabilityInfo?.category_title,
        description: sustainabilityInfo?.category_description,
      }
      : null;
  };

  return { getSustainabilityLevel };
}
